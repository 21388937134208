<template>
  <div class="card card-body filterItem">
    <hot-offer-icon class="hot-offer-icon" :color="badgeColor" :text="badgeText" v-if="isSalesDeal" direction="left"/>
    <img src="/assets/img/hot-offer.png" v-if="isDiscounted && !isSalesDeal" class="hot-offer-icon" />
    <div class="row">
      <!-- <div class="col-12 col-md-9 imgRoom"> -->
      <div class="col-12" :class="device==='desktop' && isOdysseySite ? 'col-md-11' : 'col-md-9 imgRoom'">
        <div class="row no-gutters flex-md-row">

          <div class="col-5 col-md-3 sportthumble d-lg-block">
            <div class="select-fav">
              <input type="checkbox" class="custom-control-input" :id="`fab-${itemIndex}`"/>
              <label class="fav" :for="`fab-${itemIndex}`"></label>
            </div>
            <img v-bind="mainProps" :src="hotel.imgUrl ? hotel.imgUrl : 'null'" alt=""/>
          </div>

          <div class="col-7 col-md-9">
            <div class="row">
              <div class="col-12 pl-md-4 d-flex flex-column position-static" :class="isOdysseySite ? 'col-md-8': 'col-md-12'">
                <h3 class="title">
                  <a :href="`/hotel-info/?${hotelInfoQuery}`" onclick="return false;"  @click="() => gotoHotelsPage(`/hotel-info/?${hotelInfoQuery}`)">
                    {{ hotelName || hotel.name }}
                  </a>
                  <b-badge :variant="hotel.supplierCode !== 'ATLANTIS' ? 'success' : 'danger'">
                    <span v-if="hotel.supplierCode === 'ATLANTIS'">{{$t("search-result.domestic-search.request")}}</span>
                    <span v-else>{{$t("search-result.domestic-search.available")}}</span>
                  </b-badge>
                </h3>

                <span class="item-block">
                  <i class="fas fa-user-friends bluefly"></i>
                  <strong>{{ $t("search-result.domestic-search.occupancy") }}:</strong>
                  {{hotel.adults}} {{ $t("product-page.adults")}}, {{hotel.children}} {{ $t("product-page.children")}}
                </span>
                <span class="item-block">
                  <i class="fa fa-utensils bluefly"></i>
                  <strong>{{ $t("booking.board-basis") }}:</strong>
                  {{ hotel.basisName ? hotel.basisName : $t("basis-name." + hotel.basis.toLowerCase().trim())}}
                </span>
                <span class="item-block">
                  <i class="fas fa-sort-numeric-up bluefly"></i>
                  <strong>{{ $t("home.total-night") }}:</strong>
                  {{ getDuration(hotel.checkIn, hotel.checkOut) }}
                </span>
                <span class="item-block" v-if="isOdysseySite || device === 'mobile'">
                  <img class="bluefly ml-2" src="assets/img/icon8.png" alt="" />
                  <strong>{{ $t("product-page.room-type") }}:</strong>
                  {{ hotel.roomName }}
                </span>
                <!-- <span class="item-block">
                  <i class="fas fa-check-circle bluefly"></i>
                  <strong>{{ $t("search-result.domestic-search.availability") }}:</strong>
                  {{$t("product-page.for")}} {{hotel.city}} {{ $t("search-result.domestic-search.for-dates") }} {{ getDuration(hotel.checkIn, hotel.checkOut)}}
                </span> -->

                <span class="item-block desktop-remark" v-if="hotel.supplierCode === 'ATLANTIS'">
                  <i class="fas fa-check-circle bluefly"></i>
                  <strong>{{ $t("product-page.remarks") }}:</strong>
                  {{ hotel.remark }}
                </span>
                <span class="item-block desktop-remark" v-if="hotel.adminRemark && hotel.supplierCode !== 'ATLANTIS'">
                  <i class="fas fa-check-circle bluefly"></i>
                  <strong>{{ $t("product-page.remarks") }}:</strong>
                  {{ hotel.adminRemark }}
                </span>
                <span class="item-block desktop-remark" v-if="creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
                  <i class="fas fa-check-circle bluefly"></i>
                  <strong>{{ $t("product-page.remarks") }}:</strong>
                  {{ creditCardRemark }}
                </span>

                <button class="btn btn-primary btn-selectRoom" @click="orderNow">
                  {{ $t("search-result.order-now") }}
                </button>
              </div>

              <div class="col-12 col-md-4" v-if="isMEMSHAKAgent && isOdysseySite && device==='desktop'">
                <label>{{ $t('search-result.discount-calculator') }}</label>
                <b-form-select v-model="manualDiscount">
                  <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
                </b-form-select>
                <p v-if="hotel.supplierCode!=='ATLANTIS'"><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
                <p v-else-if="hotel.supplierCode==='ATLANTIS' && hotel.category"><span>{{ $t('search-result.category') }}: </span>{{ `${hotel.categoryName}` }}</p>
              </div>

              <div class="col-12 col-md-4" v-else-if="isOdysseySite && device==='desktop'">
                <p v-if="hotel.supplierCode!=='ATLANTIS'"><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
                <p v-else-if="hotel.supplierCode==='ATLANTIS' && hotel.category"><span>{{ $t('search-result.contracted-rates') }} </span></p>
              </div>
            </div>

            <div class="my-2 text-center" v-if="device==='desktop' && isOdysseySite && rateRemarkForAgent">
              <strong class="rate-remark">{{ rateRemarkForAgent }}</strong>
            </div>
          </div>
        </div>
        <div class="my-2 text-center" v-if="device==='mobile' && isOdysseySite && rateRemarkForAgent">
          <strong class="rate-remark">{{ rateRemarkForAgent }}</strong>
        </div>
      </div>

      <div class="actionRoom col-12 col-md-3 row">
        <!-- <div class="col-md-1" :class="isOdysseySite ? 'col-4':'col-8'"> -->
        <div class="col-md-1" :class="isOdysseySite ? 'col-3':'col-8'">
          <span class="item-block mobile-remark" v-if="remark !== ''">
            <i class="fas fa-check-circle bluefly"></i>
            <strong>{{ $t("product-page.remarks") }}:</strong>
            {{ remark }}
          </span>
          <span class="item-block mobile-remark" v-if="creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
            <i class="fas fa-check-circle bluefly"></i>
            <strong>{{ $t("product-page.remarks") }}:</strong>
            {{ creditCardRemark }}
          </span>
        </div>

        <div class="col-md-2 col-4" v-if="isMEMSHAKAgent && isOdysseySite && device==='mobile'">
          <label>{{ $t('search-result.discount-calculator') }}</label>
          <b-form-select v-model="manualDiscount">
            <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
          </b-form-select>
          <p v-if="hotel.supplierCode!=='ATLANTIS'"><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
          <p v-else-if="hotel.supplierCode==='ATLANTIS' && hotel.category"><span>{{ $t('search-result.category') }}: </span>{{ `${hotel.categoryName}` }}</p>
        </div>

        <div class="col-md-9 col-4" v-if="isOdysseySite"></div>

        <!-- <div class="col-md-9 col-4 ody-price" v-if="isOdysseySite">
          <div v-if="isMEMSHAKAgent">
            <p class="large" style="font-size:2rem;">${{ discountedPrice }}</p>
          </div>
          <div v-else-if="hotel.supplierCode === 'ATLANTIS'">
            <p class="large m-0">{{ $t('search-result.full-price') }}: <span>${{ discountedPrice }}</span></p>
            <p class="large m-0">{{ $t('search-result.net-price') }}: <span>${{ Math.round(hotel.total * (1 - 3 / 100)) }}</span></p>
          </div>
          <div v-else>
            <p class="large m-0">{{ $t('search-result.full-price') }}: <span>${{ discountedPrice }}</span></p>
            <p class="large m-0">{{ $t('search-result.net-price') }}: <span>${{ Math.round(hotel.total * (1 - commission / 100)) }}</span></p>
          </div>
          <p class="m-0" v-if="!isMEMSHAKAgent"><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
          <p class="m-0 price-label">{{ $t("search-result.domestic-search.final-price-message") }}</p>
          <button class="btn btn-primary btn-selectRoom" @click="orderNow">
            {{ $t("search-result.order-now") }}
          </button>
        </div> -->
        <div class="col-md-9 col-4" v-else>
          <!-- <p class="mb-0">{{ $t("search-result.total-per-person") }}</p> -->
          <div v-if="hotel.discountPercent">
            <p class="m-0"><s>${{ Math.round(hotel.total) }}</s></p>
            <p class="large">${{ discountedPrice }}</p>
          </div>
          <div v-else>
            <!-- <p class="m-0"><s>${{ Math.round((Number(hotel.total))) }}</s></p> -->
            <p class="large">${{ discountedPrice }}</p>
          </div>
          <p class="m-0 price-label">{{ $t("search-result.domestic-search.final-price-message") }}</p>
          <button class="btn btn-primary btn-selectRoom" @click="orderNow">
            {{ $t("search-result.order-now") }}
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-if="!isOdysseySite">
      <table class="table table-striped vacation-tbl">
        <thead>
          <tr>
            <th>
              <i class="fas fa-hand-holding-usd"></i><br />{{$t("search-result.domestic-search.total-to-pay")}}
            </th>
            <th>
              <i class="fas fa-hotel"></i><br />{{$t("search-result.domestic-search.basis")}}
            </th>
            <th>
              <!-- <img src="assets/img/icon8.png" alt="" /><br />{{ $t("search-result.domestic-search.vacation-type") }} -->
              <img src="assets/img/icon8.png" alt="" /><br />{{ $t("product-page.room-type") }}
            </th>
            <th>
              <i class="fas fa-moon"></i><br />{{ $t("search-result.domestic-search.no-of-nights") }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t("search-result.domestic-search.check-in") }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t("search-result.domestic-search.check-out") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> ${{ discountedPrice }} </td>
            <td> {{ hotel.basisName ? hotel.basisName : $t("basis-name." + hotel.basis.toLowerCase().trim()) }} </td>
            <td> {{ hotel.roomName }} </td>
            <td>{{ getDuration(hotel.checkIn, hotel.checkOut) }}</td>
            <td>{{ hotel.checkIn.split("-").reverse().join("/") }}</td>
            <td>{{ hotel.checkOut.split("-").reverse().join("/") }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isOdysseySite">
      <more-hotel-price-item v-for="(h, inx) in groupedPriceListHotels" :key="inx"
        :hotel="h.hotel"
        :itemIndex="itemIndex"
        :commission="commission"
        :supplierCode="hotel.supplierCode"
        :priceList="h.priceList"
        :hotelCode="h.hotel.exCode"
        :manualDiscount="manualDiscount"/>
    </div>

  </div>
</template>
<script>
import { BBadge, VBModal,
// BModal, BFormInput, BButton,
  BFormSelect, BFormSelectOption } from 'bootstrap-vue';

import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'FilterItem',
  components: {
    BBadge,
    // BModal,
    // BFormInput,
    // BButton,
    BFormSelect,
    BFormSelectOption,
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    MoreHotelPriceItem: () => import('@/components/searchResult/atoms/MoreHotelPriceItem'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      hotelList: 'GET_CAMINGO_HOTELS',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
      agentInfo: 'GET_LOGIN_AGENT_INFO',
      bigAgentInfo: 'GET_BIG_AGENT_INFO',
    }),
    badgeColor() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.badgeColor;
    },
    badgeText() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.badgeText;
      // return 'Left Text';
    },
    isSalesDeal() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.isSalesDeal;
    },
    creditCardRemark() {
      return this.hotel.matchingDiscInfo?.creditCardRemark?.replaceAll('$', '');
    },
    discountedPrice() {
      const { totalAfterDiscounted, total } = this.hotel;
      const { manualDiscount } = this;
      if (this.isOdysseySite) return Math.round(total * (1 - manualDiscount / 100));
      if (!this.isMemberLogin) return totalAfterDiscounted;
      const isAtlantis = this.hotel?.matchingDiscInfo?.atlantisDiscounted || false;
      const isCreditCardRemarkHotel = !!this.hotel?.matchingDiscInfo?.creditCardRemark || false;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscounted : totalAfterDiscounted * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    hotelName() {
      const { hotelList, hotel, lang } = this;
      if (!hotelList || hotelList.length === 0) return '';
      const matchedHotel = hotelList.data.find((ht) => ht.id === hotel?.hotelInfo?.hotelId);
      return matchedHotel?.nameTranslations[lang];
    },
    commission() {
      const { hotelList, agentInfo, isMEMSHAKAgent, bigAgentInfo, isOdysseySite } = this;
      if (!hotelList || hotelList.length === 0) return 0;
      const hotel = hotelList.data.find((ht) => ht.id === this.hotel?.hotelInfo?.hotelId);
      if (isMEMSHAKAgent) return hotel?.commission || 0;
      let comm = agentInfo && this.hotel.supplierCode === 'ATLANTIS' ? agentInfo.commissionForFcHotels || agentInfo.commission : agentInfo?.commission || hotel.commission;
      if (isOdysseySite) {
        const hotelComm = hotel?.commission || 0;
        const matchedRule = bigAgentInfo?.find((item) => item.fcCommPct === hotelComm && ((this.hotel.supplierCode === 'ATLANTIS') === item.forFcHotel));
        const bigComm = matchedRule?.bigAgentCommPct || 0;
        const littleCom = matchedRule?.littleAgentCommPct || 0;
        comm = ((agentInfo.bigAgent) ? bigComm : littleCom) || comm;
      }
      return comm;
    },
    groupedPriceListHotels() {
      const hotels = this.hotel.moreHotels;
      if (hotels.length === 0) {
        return [];
      }
      const result = [];
      const gHotels = {};
      hotels.forEach((elm) => {
        const { priceList } = elm;
        if (gHotels[priceList]) {
          gHotels[priceList].push(elm);
        } else {
          gHotels[priceList] = [];
          gHotels[priceList].push(elm);
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const value of Object.entries(gHotels)) {
        const sortedArray = this.sortArray(value[1], 'totalAfterDiscounted');
        if (sortedArray[0]) {
          sortedArray[0].restHotels = sortedArray;
          result.push({ priceList: sortedArray[0].priceList, hotel: sortedArray[0] });
        }
      }
      return result;
    },
    rateRemarkForAgent() {
      const { lang } = this;
      return this.hotel.rateRemarkForAgent?.[lang] || '';
    },
    hotelInfoQuery() {
      const { hotel } = this;
      return new URLSearchParams({ city: this.query.city, hotelCode: hotel.exCode }).toString();
    },
  },
  data() {
    return {
      mainProps: {
        blankColor: '#bbb',
        class: 'img-fluid rounded',
      },
      query: this.$route.query,
      phone: '',
      email: '',
      nameState: null,
      isDiscounted: false,
      remark: this.hotel.supplierCode !== 'ATLANTIS' ? this.hotel.adminRemark : this.hotel.remark,
      manualDiscount: 0,
    };
  },
  watch: {
    hotel() {
      // this.setHotelValues();
    },
  },
  created() {
    // this.setHotelValues();
  },
  mounted() {
    this.isDiscounted = this.hotel.isDealDiscounting || !!this.hotel.isSpecialDiscounting;
    setTimeout(() => {
      this.manualDiscount = Number(this.hotel.discountPercent);
      if (this.manualDiscount > 0) console.log(this.hotel);
    }, 1000);
  },
  methods: {
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
    getDate(str) {
      return dayjs(str, 'YYYY-MM-DD').format('MM/DD');
    },
    orderNow() {
      if (this.isOdysseySite || this.hotel.count === 1 || this.hotel.supplierCode === 'ATLANTIS' || this.hotel.exCode === this.query.exCode) {
        let body = {
          city: this.query.city,
          hotelCode: this.hotel.exCode,
          checkIn: this.query.checkIn,
          checkOut: this.query.checkOut,
          adult: Number(this.query.adult),
          child: Number(this.query.child),
          infant: Number(this.query.infant),
          lang: this.query.lang,
          includeFlight: this.query.includeFlight,
          suppliers: this.hotel.supplierCode || this.hotel.suppliers,
          returnLog: this.query.returnLog,
          priceList: this.hotel.priceList,
          roomCode: this.hotel.roomCode,
          basisCode: this.hotel.basis,
          manualDiscount: this.manualDiscount,
        };
        const { marketerId, subMarketerId } = this;
        if (marketerId && marketerId !== '') body = { ...body, marketerId };
        if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };

        // if (window.location.host.includes('localhost')) {
        this.$router.push({ path: '/product-page', query: body });
        // } else {
        //   const routeData = this.$router.resolve({ path: '/product-page', query: body });
        //   window.open(routeData.href, '_blank');
        // }
      } else {
        const { exCode } = this.hotel;
        if (exCode === this.query.exCode) return;

        const query = JSON.parse(JSON.stringify(this.query));

        query.exCode = exCode;
        query.hotelCode = exCode;
        query.suppliers = this.hotel.supplierCode || this.hotel.suppliers;
        delete query.sources;

        this.$router.push({ path: '/search-result', query });
      }
    },
    goSmartAgency() {
      if (!this.checkFormValidity()) {
        return;
      }
      const body = {
        checkin: this.hotel.checkIn.split('-').reverse().join('/'),
        checkout: this.hotel.checkOut.split('-').reverse().join('/'),
        hotelID4Mail: this.hotel.exCode,
        clientemail: this.email,
        phone: this.phone,
        dest: this.query.city,
        source: 'FLYING',
        price: this.hotel.totalAfterDiscounted,
        adult: this.hotel.adults,
        child: this.hotel.children,
        baby: this.hotel.infant,
      };

      window.location.href = `https://m.flyingcarpet.co.il/deals/api/apiMobile/AddToSmartAgent?${new URLSearchParams(body).toString()}`;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.email = '';
      this.phone = '';
    },
    sortArray([...arr], sortItem, order = 1) {
      arr.sort((a, b) => {
        if (Number(a[sortItem]) > Number(b[sortItem])) {
          return order;
        } else if (Number(a[sortItem]) < Number(b[sortItem])) {
          return -1 * order;
        }
        return 0;
      });
      return arr;
    },
    gotoHotelsPage(link) {
      const routeData = this.$router.resolve({ path: link });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.rate-remark {
  color: #007bff;
  font-size:1.2rem;
  line-height: 1.1rem;
}
.bluefly {
    color: #2486c2;
    width: 20px;
}
.hot-flight {
  background-image: url("/assets/img/hot-offer-big.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50px 50px;
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -8px;
  left: -8px;
  position: absolute;
  z-index: 1;
}
.dVacation-pack .ticket-topPart-big .box,
.dVacation-pack .ticket-bottomPart-big .box {
  min-width: 83px;
}
.price-down-message {
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}
.price-down-message:hover {
  color: #111;
  text-decoration: none;
  outline: none;
}
@media (max-width: 479px) {
  .hot-flight {
    background-size: 20px 20px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
  }
}

.sportspage_area .actionRoom span.item-block.mobile-remark{
  display: none;
  font-size: 14px;
  line-height: 20px;
}
.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}
.ui.rating {
  padding-right: 0;
}
.itemTags {
  line-height: 30px;
}
.table td {
  text-align: center;
}
.flight-select {
  position: absolute;
  top: calc(50% - 14px);
  right: 0;
  padding: 0;
}
.filterItem {
  position: relative;
  padding: 10px;
  box-shadow: none;
}
.sportspage_area .filterItem .actionRoom .large {
  white-space: nowrap;
}
.sportspage_area .filterItem .actionRoom p {
  /* white-space: nowrap; */
  line-height: 1.3rem;
}
.sportspage_area .filterItem .actionRoom .ody-price .large {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .desktop-remark{
    display: none;
  }
  .sportspage_area .actionRoom span.item-block.mobile-remark{
    display: flex;
    width: fit-content;
  }
  .sportspage_area .filterItem .actionRoom .ody-price .large, .sportspage_area .filterItem .actionRoom .large {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}
</style>

<style>
.flight-select .custom-control {
  padding-left: 0;
}
.flight-select .custom-control-label {
  padding-right: 1.2rem;
}
.flight-select .custom-control-label::before,
.flight-select .custom-control-label::after {
  right: 0;
}
</style>
